.mainCard {
    height: auto;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    display: flex !important;
}

.dimmed.dimmable > .ui.modals.dimmer.visible {
    display: flex !important;
}
